@import "../../styles/utils";

.dropdown {
  position: relative;
}

.dropdownTop {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdownTitle {
  color: $grey;
  font-weight: 500;
  font-size: 1.4rem;
  margin-right: .7rem;
  letter-spacing: -0.017rem;

  @include tablet {
    display: none;
  }
}

.mobileVisible {
  @include tablet {
    display: block !important;
  }
}

.dropdownArrow {
  svg {
    width: 1rem;

    polyline {
      stroke: $grey;
    }
  }

  @include tablet {
    display: none;
  }
}

.dropdownContent {
  position: absolute;
  top: 2.5rem;
  right: -1.4rem;
  max-height: 0;
  z-index: 999;
  overflow: hidden;
  background-color: white;
  transition: max-height .2s linear;
  box-shadow: 0 0 30px -15px rgba(1, 50, 62, 0.3);

  * {
    opacity: 0;
    transition: opacity .2s linear;
  }

  @include tablet {
    position: fixed;
    top: 7rem;
    left: 2rem;
    width: calc(100% - 4rem) !important;
  }
}

.dropdownIcon {
  display: none;
  @include tablet {
    display: block;
  }
}

.dropdown {
  &.isOpen {
    .dropdownContent {
      max-height: 400px;

      * {
        opacity: 1;
      }
    }

    .dropdownIcon {
      svg {
        circle, path, use {
          stroke: $highlight;
        }
      }
    }
  }

  &.account {
    .dropdownContent {
      width: 35rem;
    }
  }
}
