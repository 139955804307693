@import "../../styles/utils";

.big-chart-wrapper {
  margin-bottom: 4.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.finalSpacer {
  margin-right: 5vh;
}

.flexWrapper {
  display: flex;
  align-items: end;
  justify-content: space-between;
  .flexColumn {
    flex-direction: column;
  }
}

.modal-content {
  padding: 0 auto;
}

.modal {
  padding-left: 20vh;
  padding-right: 20vh;
  width: unset !important;
  .zusatzsparen-modal-content {
    padding-bottom: 10vh;
  }
}

.mobile-modal-header {
  display: none;
  @include tablet {
    display: block;
    margin-bottom: 12rem;
  }
}

.mobile-modal-title {
  @include tablet {
    margin-top: 4.5rem !important;
  }
}

.profile-modal-wrapper {
  @include tablet {
    margin: 0 2.3rem;
  }
}
.profile-modal-content {
  @include tablet {
    padding: 2.4rem !important;
  }
}

.profile-mobile-modal-text {
  @include tablet {
    color: $content;
    font-family: Inter;
    font-weight: 300;
  }
}
