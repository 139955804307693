@import "../../../styles/utils";

.headerLogo {
    max-height: 4.1rem;
}

.headerContainer {
    padding: 4.8rem;
    border-bottom: .1rem solid #F5F5F5;

    @include tablet {
        padding-bottom: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.headerBottom {
    display: flex;
    margin-top: 5.2rem;
    justify-content: space-between;

    @include tablet {
        margin-top: 4rem;
        flex-direction: column;
    }
}

.headerTitle {
    @include tablet {
        margin-bottom: 2rem;
    }
}

.headerTop{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
