.kontakt {
  .kontakt-daten {
    padding-left: 0;
    list-style: none;

    li {
      font-size: 1.4rem;
      font-weight: 300;
    }
  }

  .text, .title-card {
    font-weight: 600;
  }
}

.contact-text-area div:first-child {
  height: auto !important;
  min-height: auto !important;
  max-height: 100% !important;
}

.contact-text-area div:first-child div:nth-child(2) textarea {
  height: auto !important;
}
