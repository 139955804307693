@import  "../../styles/utils";

.pill {
  width: auto;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 1.2rem;
  font-size: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  text-transform: uppercase;
}

.error {
  color: white;
  background-color: $error;
}

.warning {
  color: white;
  background-color: $warning;
}
