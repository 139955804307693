.chartsContainer {
  background-color: #fff;
  width: 100%;
  position: relative;
}

.tooltip {
  position: absolute;
  padding: 13px;
  border-radius: 8px;
  background-color: #242f38;
  box-shadow: 0 27px 100px -20px rgba(38, 50, 56, 0.2),
    0 4px 15px -10px rgba(38, 50, 56, 0.2),
    0 9px 30px -15px rgba(38, 50, 56, 0.2),
    0 18px 60px -30px rgba(38, 50, 56, 0.2);
}

// .rect {
//   margin-left: 75px;
//   margin-top: 10px;
//   height: 25px;
//   width: 25px;
//   transform: rotate(-45deg);
//   border-radius: 2px;
//   background-color: rgb(36, 47, 56);
//   position: absolute;
// }
.tooltipDate {
  color: #ffffff;
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
}

.tooltipAmount {
  color: #ffffff;
  font-family: Inter;
  font-size: 12px;
  font-weight: bold;
}

.ul {
  list-style: none;
  margin-left: -25px;
  margin-top: 20px;
}