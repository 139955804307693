@import "../../styles/utils";

.loaderWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.loaderOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: crosshair;
    pointer-events: none;
    overscroll-behavior: contain;
    background-color: rgba(255, 255, 255, 0.5);
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    display: block;
    border-radius: 50%;
    border: 8px solid $action;
    transform: translate(-50%, -50%);
    animation: loader 1s linear infinite;
    border-color: $action $action transparent transparent;
}

@keyframes loader {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
