@import "../../../styles/utils";

.dashboard {
  padding: 3.2rem;

  &.blurred {
    filter: blur(0.72rem);
  }
}

.flexWrapper {
  display: flex;
  align-items: end;
  justify-content: space-between;
  .flexColumn {
    flex-direction: column;
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subtitle {
  font-size: 1.6rem;
  letter-spacing: 0;
  margin-right: 4vh;
  line-height: 2.2rem;
  font-weight: 600;
}

.doubleTitle {
  margin-bottom: 1vh;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
}

.doubleTitleTop {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
}

.finalSpacer {
  margin-right: 5vh;
}

.title {
  font-size: 3.4rem;
  letter-spacing: 0;
  line-height: 3.4rem;
  margin-bottom: 0.8rem;
  font-weight: 600;
}

.text {
  font-size: 1.4rem;
  line-height: 2rem;
  color: $grey;
  font-weight: 500;
}

.icon {
  cursor: pointer;
}

.cardTop {
  .top {
    margin: 0;
  }
  .title {
    font-size: 4.8rem;
    letter-spacing: 0;
    line-height: 5.6rem;
  }
}
