@import "../../../styles/utils";

path.color0 {
    fill: url(#donutGradient);
}

path.color1 {
    fill: $light-grey;
}

.stop-left-chart {
    stop-color: $blue-gradient-start;
}

.stop-right {
    stop-color: $blue-gradient-end;
}

.background-donut-chart {
    border: 2rem solid $light-grey;
    z-index: 0;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.donut-chart-wrapper {
    @include tablet {
        margin-left: -4rem;
    }
}

.middle-text {
    font-size: 1.2rem;
    text-align: center;
    line-height: 2rem;
    color: $grey;
    font-weight: 500;
    max-width: 150px;
}

.middle-number {
    font-size: 2.4rem;
    font-family: Inter;
    font-weight: 600;
}

.donut-main {
    z-index: 1;
    position: absolute;
}