@import "../../styles/utils";

.columns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &.width {
    justify-content: unset;
  }
}

.header {
  color: $grey;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2rem;
  margin-bottom: 0.6rem;
}

.text {
  color: $content;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  @media (max-width: 768px) {
    padding-bottom: 1rem;
  }
}

.column {
  @include tablet {
    width: 50% !important;
  }
}
