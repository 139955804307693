@import "src/styles/utils";

.wrapper {
    cursor: pointer;
    margin-left: 8px;

    & * {
        transition: all, .3s ease;
    }

    &:hover {
        .icon {
            circle, path {
                stroke: $highlight;
            }

            path{
                fill: $highlight;
                stroke: $highlight;
            }
        }
    }
}

.overlay {
    max-width: 260px;
}

