@import "../../../styles/utils";

rect.color-bar-0 {
    fill: url(#barGradientDeActive);
}

rect.color-bar-1 {
    fill: url(#barGradientActive);
}
.stop-left-bar-chart {
    stop-color: $blue-gradient-start;
}

.stop-right-bar-chart {
    stop-color: $blue-gradient-end;
}
.stop-left-bar-chart-gray {
    stop-color: $light-gray-gradient-start;
}

.stop-right-bar-chart-gray {
    stop-color: $light-gray-gradient-end;
}

.x-axis-bar-chart .tick text {
    font-size: 1.4rem;
    fill: $grey;
}

#bar-chart-main {
    margin-bottom: 4.8rem; 
}
