@import "bootstrap.css";
@import "utils";
@import "typography";

html {
  font-size: 10px;
}

body {
  font-size: 16px;
  height: 100%;
  margin: 0;
  color: $content;
  background-color: $light-grey;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.loading {
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  //height: 100%;
}

//h1 { @include heading(3.6rem, 4.4rem); }
h1 {
  @include heading(2.4rem, 3.2rem);
}

h2 {
  @include heading(2rem, 2.8rem);
}

h3 {
  @include heading(1.8rem, 2.6rem);
}

h4 {
  @include heading(1.6rem, 2.4rem);
}

h5 {
  @include heading(1.4rem, 2rem);
}

h6 {
  @include heading(1.2rem, 1.8rem, 700);
}

p {
  margin: 0;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  font-weight: 300;
}

b {
  font-weight: 600;
}

.paragraph {
  font-size: 1.6rem;
  letter-spacing: 0;
}

.text-small {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.text-medium {
  font-size: 1.4rem;
  line-height: 2rem;
}

.text-large {
  font-size: 1.8rem;
  line-height: 2.6rem;
}

.paragraph {
  font-size: 1.6rem;
  letter-spacing: 0;
}

button {
  outline: none !important;
}

.main-container {
  //height: 100%;
}

.main-content {
  //height: 100%;
}

.content-wrapper {
  position: relative;
  height: calc(100vh - 80px);
  overflow-y: auto;
  padding: 4.8rem 4.8rem 4.8rem 4.8rem;

  &.has-active-impersonation {
    height: calc(100vh - 112px);

    @include tablet {
      height: calc(100vh - 128px);
    }
  }

  @include tablet {
    padding-left: 2.2rem;
    padding-right: 2.2rem;
    padding-bottom: 4.8rem;
  }

  h4[data-testid="notification-title"] {
    font-size: 16px;
  }

  p[data-testid="notification-description"] {
    font-size: 14px;
    line-height: 20px;
  }
}

.modal-buttons {
  display: flex;
  align-items: center;

  button:first-child {
    margin-left: auto;
    margin-right: 3.2rem;
  }

  @include tablet {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    button:first-child {
      margin: auto;
      margin-top: 3.2rem;
    }

    button:last-child {
      width: 100%;
      order: -1;
    }
  }
}

.modal {
  position: relative;
  display: block;
  width: 98rem;
  max-width: 100vw;
  max-height: 100vh;
  height: auto;
  margin: auto;
  z-index: 10;
  overflow: auto;

  @include tablet {
    top: 0;
    width: 100vw;
    min-height: 100vh;
    padding: 0 2rem;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: $light-grey;

  @include tablet {
    overflow: scroll;
  }
}

.modal-close-button {
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  cursor: pointer;

  svg rect {
    fill: $action;
  }

  @include tablet {
    top: 4.8rem;
  }
}

.mdc-tab {
  background: none;
  border: none;
  outline: none;
  padding: 1.4rem 0;

  &:not(:last-child) {
    margin-right: 4rem;
  }

  &.mdc-tab--active {
    border-bottom: 0.2rem solid $highlight;

    .mdc-tab__text-label {
      color: $highlight;
    }
  }

  .mdc-tab__text-label {
    color: $grey;
    font-size: 1.4rem;
    font-weight: bold;
    background: none;
    text-transform: uppercase;
  }
}

.page-top {
  margin-bottom: 7.2rem;
}

.two-column-layout {
  display: flex;

  .left {
    flex: 2;
    padding-right: 2.4rem;
  }

  .right {
    flex: 1;
    padding-left: 2.4rem;
  }

  @include tablet {
    flex-direction: column;

    .left {
      padding: 0;
      margin-bottom: 3.2rem;
    }

    .right {
      flex: 1;
      padding: 0;
    }
  }
}

.bottom-button {
  @include tablet {
    margin-top: auto;
    width: 100%;
  }
}

.bottom-button-group {
  @include tablet {
    margin-top: auto;
    width: 100%;
  }
}

button {
  svg.arrow-white {
    path,
    polyline {
      stroke: white;
    }
  }

  &:disabled {
    svg.arrow-white {
      path,
      polyline {
        stroke: #D7D8D9;
      }
      line {
        stroke: #D7D8D9;
      }
    }
  }
}

// Style for RC-Tooltip
.rc-tooltip {
  opacity: 1 !important;

  &.rc-tooltip-placement-topLeft {
    & .rc-tooltip-arrow {
      left: 0.9rem;
      bottom: 0.5rem;
    }
  }

  &.rc-tooltip-placement-topRight {
    & .rc-tooltip-arrow {
      right: 0.4rem;
      bottom: 0.5rem;
    }
  }

  & .rc-tooltip-content {
    & .rc-tooltip-inner {
      background-color: $content !important;
    }

    & .rc-tooltip-arrow {
      border-top-color: $content;
    }
  }
}

.mobile-remove-line-break {
  @include tablet {
    display: none;
  }
}

.mobile-line-break {
  display: none;

  @include tablet {
    display: block;
  }
}

.tablet-hidden {
  @include tablet {
    display: none !important;
  }
}

.tablet-visible {
  display: none;

  @include tablet {
    display: block;
  }
}

.user-dropdown-content {
  right: 0 !important;

  p {
    word-break: break-all;
  }
}

.fade-in {
  animation: fadeInCustom 0.5s linear;
}

.dv-logo,
.dv-logo-lite {
  display: none !important;
}

@keyframes fadeInCustom {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rpv-core__inner-page {
  background-color: transparent;
}

.rpv-core__doc-loading {
  //height: 70vh;
  display: none;
  //background-color: transparent;
}

.ReactModal__Body--open.pdf {
  .loader-wrapper {
    width: 80px;
    height: 80px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .loader-overlay {
      display: none;
    }
  }
}

iframe[style*="z-index: 2147483647"] {
  display: none !important;
  z-index: 0 !important;
}
