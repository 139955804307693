@import "../../styles/utils";


.steps {
  display: flex;
  align-content: center;
  
  @include tablet {
    justify-content: space-between;
  }
}

.step {
  display: flex;
  color: $grey;
  margin-left: 4rem;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  line-height: 1.8rem;
  letter-spacing: -0.015rem;
  text-transform: uppercase;

  .stepIcon {
    width: 2.4rem;
    height: 2.4rem;
    text-align: center;
    border-radius: 50%;
    margin-right: 1rem;
    line-height: 2rem;
    letter-spacing: normal;
    border: .2rem solid $grey;

    svg {
      margin-bottom: .1rem;
    }
  }

  &.current {
    color: $highlight;
    .stepIcon {
      color: white;
      border-color: $highlight;
      background-color: $highlight;
    }
  }

  &.completed {
    color: $content;
    .stepIcon {
      color: white;
      border-color: $content;
      background-color: $content;
    }
  }

  .stepText {
    @include tablet {
      display: none;
    }
  }

  @include tablet {
    margin: 0;
  }
}
