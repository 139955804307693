@import "../../styles/utils";

.dokumente-page {

    .list-view{

        .header {
            color: $grey;
            font-size: 1.4rem;
            font-weight: 500;
            letter-spacing: 0;
            padding: 0 3.2rem;
            margin-bottom: 2.4rem;
            display: flex;
            justify-content: space-between;
            @include tablet {
                display: none;
              }
        }

        .content {
            font-size: 1.4rem;
            display: flex;
            padding: 3.2rem;
            max-height: 8.8rem;
            align-items: center;
            justify-content: space-between;
            @include tablet {
              flex-wrap: wrap;
              margin-top: 2rem;
            }
        }

        .row-wrapper{
            margin-bottom: 2.4rem;
        }
        
        .read{
            font-weight: normal;
        }
        .unread{
            font-weight: 600;
        }

        .column:nth-child(1) {
            flex: 0 0 23%;
        }
        .column:nth-child(2) {
            flex: 0 0 50%;
            @include tablet {
                flex: 0 0 25%;
            }
        }
        .column:nth-child(3) {
            flex: 0 0 22%;
            display: flex;
            align-items: center;
        }
        .column:nth-child(4) {
            flex: 0 0 5%;
        }
        .dokumenttyp{
            @include tablet {
              display: none;
            }
        }
        .dokumenttyp-mobile{
            display: none;
            @include tablet {
              display: block;
              width: 100%;
              margin-top: 1rem;
              margin-bottom: 2rem;
            }
        }

    }

}
