@import "../../styles/utils";

.card {
  position: relative;
  border-radius: 0.8rem;
  background-color: white;
  box-shadow: 0 18px 60px -30px rgba(38, 50, 56, 0.2);
}

.register {
  height: auto;
  width: 98rem;
  min-height: 36rem;

  @include tablet {
    width: 100%;
    min-height: 100vh;
    border-radius: 0;
  }
}

.login {
  height: auto;
  width: 65rem;
  padding: 3.2rem 3.2rem 4.8rem 3.2rem;

  @include tablet {
    width: 100%;
    height: auto;
    min-height: 100vh;
    border-radius: 0;
    padding: 4.8rem 2rem;
  }
}

.accessRevoked {
  height: auto;
  width: 60rem;
  padding: 3.2rem 3.2rem 1rem 3.2rem;

  @include tablet {
    width: 100%;
    height: auto;
    min-height: 100vh;
    border-radius: 0;
    padding: 4.8rem 2rem;
  }
}

.expired {
  height: auto;
  width: 960px;
  padding: 3.2rem 5rem 1rem 5rem;

  @include tablet {
    width: 100%;
    height: auto;
    min-height: 100vh;
    border-radius: 0;
    padding: 4.8rem 2rem;
  }
}

.auto {
  width: auto;
}

.full {
  width: 100%;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.standard {
  padding: 4.8rem;
  @include tablet {
    padding: 2.8rem;
  }
}

.modal {
  padding: 4.8rem;
  border-radius: 0.8rem;
}

.blurred {
  pointer-events: none;
  box-shadow: 0 0 1.2rem 0.8rem white;
}
