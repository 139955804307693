@import "../../styles/utils.scss";

.versicherungen-page-content {
}

.expended-more-box {
    padding: 3.2rem 14.5rem 3.2rem 3.2rem;
    @include tablet {
        padding: 2.4rem 6rem 2.4rem 2.4rem;
    }
}
.expended-more-box p {
    margin-bottom: 2.4rem;
}

.expended-more-box-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $content;
    font-family: Inter;
    font-weight: 300;
    margin-bottom: 1.6rem;
    @include tablet {
        flex-direction: column;
    }
}

.expended-more-box-amount-col{
    @include tablet {
        align-self: flex-end;
    }
}
.expended-more-box-row div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
}
.expended-more-box-row div:nth-child(2) div:first-child {
    margin-right: 5rem;
}
.expended-more-box-row div:nth-child(2) div {
    width: 7rem;
}

.expended-more-box-subtitle {
    font-size: 1.2rem;
    margin-left: 1.2rem;
    @include tablet {
        margin-left: 0;
    }
}

.expended-more-buttons-wrapper {
    padding: 0 0 4.8rem 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: -2rem;
    font-size: 1.6rem;
    color: $content;
    font-family: Inter;
    font-weight: 300;
}

.expended-more-buttons-wrapper p {
    margin-top: 5rem !important;
}

.expended-more-table-header {
    font-size: 1.4rem;
    color: $grey;
    font-family: Inter;
    font-weight: 500;
}
.expended-more-table {
    width: 7rem;
}

.divider {
    width: 93%;
    margin-top: -16px;
    margin-bottom: -8px;
    margin-left: 3.5%;
}

.pensionierung-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 3.2rem;
    padding-top: 3.2rem;
}

.regular-retirement-box-title {
    @include tablet {
        width: 55%;
    }
}

.list-container {
    & button {
        margin-bottom: 4.8rem;
    }

    &:last-child button {
        margin-bottom: 0;
    }
}

.expandable-row-content {
    display: flex;
    justify-content: space-between;
    padding: 32px;

    @media (max-width: 768px) { padding: 2.4rem;}
}
