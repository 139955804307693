$content: #242f38;
$action: #145685;
$highlight: #1b8db3;
$distinct: #77b324;
$grey: #969899;
$light-grey: #f3f3f3;

$error: #cc3f3d;
$warning: #e67417;
$verification: #138057;

$action-darker: #10446a;
$error-darker: #991533;

$blue-gradient-start: #6bb5ce;
$blue-gradient-end: #b0d7e5;

$light-gray-gradient-start: #d2d4d6;
$light-gray-gradient-end: #e1e4e6;

@mixin heading($font-size, $line-height, $font-weight: 600) {
  margin-bottom: 0;
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
}

.mb-12 {
  margin-bottom: 1.2rem;
}

.mb-16 {
  margin-bottom: 1.6rem;
}

.mb-24 {
  margin-bottom: 2.4rem;
}

.mb-32 {
  margin-bottom: 3.2rem;
}

.mb-40 {
  margin-bottom: 4rem;
}

.mb-48 {
  margin-bottom: 4.8rem;
}

.mb-58 {
  margin-bottom: 5.8rem;
}

.mb-94 {
  margin-bottom: 9.4rem;
}

.mt-16 {
  margin-top: 1.6rem;
}

.mt-24 {
  margin-top: 2.4rem;
}

.mt-32 {
  margin-top: 3.4rem;
}

.mt-40 {
  margin-top: 4rem;
}

.mt-48 {
  margin-top: 4.8rem;
}

.mt-58 {
  margin-top: 5.8rem;
}

.mt-94 {
  margin-top: 9.4rem;
}

.mt-16-tablet {
  @media (max-width: 768px) {
    margin-top: 1.6rem;
  }
}
.ml-05 {
  margin-left: 0.5rem;
}

.ml-16 {
  margin-left: 1.6rem;
}

.ml-24 {
  margin-left: 2.4rem;
}

.ml-32 {
  margin-left: 3.4rem;
}

.ml-40 {
  margin-left: 4rem;
}

.ml-48 {
  margin-left: 4.8rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-12-tablet {
  @media (max-width: 768px) {
    margin-left: 1.2rem;
  }
}

.mr-16 {
  margin-right: 1.6rem;
}

.mr-24 {
  margin-right: 2.4rem;
}

.mr-32 {
  margin-right: 3.4rem;
}

.mr-40 {
  margin-right: 4rem;
}

.mr-48 {
  margin-right: 4.8rem;
}

.mr-12-tablet {
  @media (max-width: 768px) {
    margin-right: 1.2rem;
  }
}

.pl-16 {
  padding-left: 1.6rem;
}

.pl-24 {
  padding-left: 2.4rem;
}

.pl-32 {
  padding-left: 3.4rem;
}

.pl-40 {
  padding-left: 4rem;
}

.pl-48 {
  padding-left: 4.8rem;
}

.pr-16 {
  padding-right: 1.6rem;
}

.pr-24 {
  padding-right: 2.4rem;
}

.pr-32 {
  padding-right: 3.4rem;
}

.pr-40 {
  padding-right: 4rem;
}

.pr-48 {
  padding-right: 4.8rem;
}

.pb-16 {
  padding-bottom: 1.6rem;
}

.pb-24 {
  padding-bottom: 2.4rem;
}

.pb-32 {
  padding-bottom: 3.4rem;
}

.pb-40 {
  padding-bottom: 4rem;
}

.pb-48 {
  padding-bottom: 4.8rem;
}

.p-16 {
  padding: 1.6rem;
}

.p-24 {
  padding: 2.4rem;
}

.p-32 {
  padding: 3.4rem;
}

.p-40 {
  padding: 4rem;
}

.p-48 {
  padding: 4.8rem;
}

.p-24-tablet {
  @media (max-width: 768px) {
    padding: 2.4rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.rotate-180 {
  transform: rotate(180deg);
}

.mb-0-tablet {
  @media (max-width: 768px) {
    margin-bottom: 0rem;
  }
}

.mb-32-tablet {
  @media (max-width: 768px) {
    margin-bottom: 3.2rem;
  }
}

.z-index-high {
  z-index: 999;
}

@mixin smaller-phone {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}

.max-w-100 {
  max-width: 100%;
}

.max-w-75 {
  max-width: 75%;
}

.max-w-50 {
  max-width: 50%;
}

.max-w-25 {
  max-width: 25%;
}

@media (max-width: 1200px) {
  .max-w-xl-100 {
    max-width: 100% !important;
  }

  .max-w-xl-75 {
    max-width: 75% !important;
  }

  .max-w-xl-50 {
    max-width: 50% !important;
  }

  .max-w-xl-25 {
    max-width: 25% !important;
  }
}

@media (max-width: 992px) {
  .max-w-lg-100 {
    max-width: 100% !important;
  }

  .max-w-lg-75 {
    max-width: 75% !important;
  }

  .max-w-lg-50 {
    max-width: 50% !important;
  }

  .max-w-lg-25 {
    max-width: 25% !important;
  }
}

@media (max-width: 768px) {
  .max-w-md-100 {
    max-width: 100% !important;
  }

  .max-w-md-75 {
    max-width: 75% !important;
  }

  .max-w-md-50 {
    max-width: 50% !important;
  }

  .max-w-md-25 {
    max-width: 25% !important;
  }
}

@media (max-width: 576px) {
  .max-w-sm-100 {
    max-width: 100% !important;
  }

  .max-w-sm-75 {
    max-width: 75% !important;
  }

  .max-w-sm-50 {
    max-width: 50% !important;
  }

  .max-w-sm-25 {
    max-width: 25% !important;
  }
}

p[data-testid="typography"],
div[data-testid="user-profile"] h5 {
  color: #969899;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.017rem;
}
