.main-text {
  font-size: 50px;
  font-family: fangsong;
  font-weight: 600;
}
.minor-text {
  font-family: fangsong;
  font-size: 20px;
  font-weight: 400;
}
