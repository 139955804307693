@import "../../styles/utils";

.dashboard-page {
  .top {
    display: grid;
    grid-gap: 4.8rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 20rem;

    padding-bottom: 10rem;

    @include tablet {
      display: unset;
    }
  }

  .main {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;

    @include tablet {
      grid-column-start: unset;
      grid-column-end: unset;
    }
  }

  .middle {
    grid-row-start: 3;
    grid-row-end: 5;

    @include tablet {
      grid-row-start: unset;
      grid-row-end: unset;
    }
  }

  .dashboard-card {
    border-radius: 0.8rem;
    transition: all 0.1s linear;

    & * {
      cursor: pointer;
    }

    &:hover {
      box-shadow: 0 2.7rem 10rem -2rem rgba(38, 50, 56, 0.2),
        0 0.4rem 1.5rem -1rem rgba(38, 50, 56, 0.2),
        0 0.9rem 3rem -1.5rem rgba(38, 50, 56, 0.2),
        0 1.8rem 6rem -3rem rgba(38, 50, 56, 0.2);

      svg {
        & g[fill="#F3F3F3"],
        & g[fill="#263238"] {
          fill: $action-darker !important;
        }

        & g[stroke="#263238"] {
          stroke: $action-darker !important;
        }
      }
    }

    @include tablet {
      margin-bottom: 3.2rem;
    }
  }

  .faq-button {
    position: fixed;
    right: 2.4rem;
    bottom: 4.8rem;
    height: 4.8rem;
    width: 4.8rem;
    transition: all 0.1s linear;
  }
}

.dashboard-bar-chart-wrapper {
  margin-top: 4.5rem;
}

.dashboard-donut-chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;
}
