@import "../../styles/utils";

.register-page {
  position: relative;
  min-height: 100vh;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 15vh 0 5vh;

  @media all and (max-height: 775px) {
    padding-top: 10vh;
    //align-items: center;
  }

  @include tablet {
    padding: 0;
    align-items: center;
  }
}

.register-content {
  padding: 4.8rem;

  @include tablet {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    height: calc(100vh - 23rem);

    &.full-height {
      height: unset;
    }
  }
}

.register-start-page {
  @include tablet {
    height: calc(100vh - 27rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.register-birthday-page {
  @include tablet {
    height: calc(100vh - 29rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.register-registration-code-message-page {
  margin-bottom: -4.8rem;
  display: flex;

  .registration-code-image {
    max-width: 45%;
  }

  @include tablet {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 0;

    .registration-code-image {
      max-width: unset;
      margin-top: 1rem;
      width: 100%;
      height: auto;
    }
  }
}

.register-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  //padding: 4.8rem;
  border-radius: 8px;
  background-color: white;
  transform: translate(-50%, -50%);
  box-shadow: 0 18px 60px -30px rgba(38, 50, 56, 0.2);

  @include tablet {
    top: 0;
    left: 0;
    transform: unset;
  }
}

.register-modal-header {
  padding-top: 4.8rem;
  padding-left: 4.8rem;
  padding-right: 4.8rem;
  border-bottom: 1px solid $light-grey;

  @include tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.register-modal-content {
  padding: 4.8rem;
  height: 100%;
  max-height: 100%;

  @include tablet {
    padding: 4.8rem 2rem;
  }
}

.register-modal-image-container {
  width: 50rem;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  position: relative;

  & .register-modal-subtext {
    font-size: 1.4rem;
    color: var(--text-color-gray);
    align-self: self-start;
    justify-self: self-start;
    position: absolute;
  }

  & .image-card + .register-modal-subtext {
    left: 5%;
    top: 70%;

    @include tablet {
      left: 3%;
    }

    @include phone {
      left: 4%;
    }
  }

  & .image-certificate + .register-modal-subtext {
    bottom: -6%;
    margin: unset !important;

    @include tablet {
      left: 16vw;
    }

    @include phone {
      left: 14vw;
    }
  }

  @include tablet {
    width: 100%;
    height: auto;
  }
}

.register-modal-image {
  max-width: 100%;
  max-height: 100%;

  &.image-card {
    margin-left: 4.4rem;

    @include tablet {
      margin-left: 3.2rem;
    }
  }

  &.image-certificate {
    margin: 0 auto;

    @include tablet {
      margin-left: -15rem;
    }

    @include phone {
      margin-left: -9rem;
      margin-bottom: 3rem;
    }
  }
}

.tabs {
  @include tablet {
    text-align: center;
  }
}

.left-align-button-wrapper button {
  margin-top: 3rem;
  @include tablet {
    text-align: start;
  }
}

.send-sms-content {
  @include tablet {
    height: calc(100vh - 7rem) !important;
  }
}

.terms-link {
  z-index: 999;
  color: $action;
  font-weight: bold;
}
