.chart {
  padding-inline: 1em;
}

.lineChart--svg {
  overflow: visible;
}

.chart--headline,
.chart--subHeadline {
  text-align: center;
}

.chart--headline {
  position: relative;
  font-weight: 100;
  font-size: 28px;
}

.chart--headline:before {
  position: absolute;
  content: "";
  bottom: 133%;
  left: 50%;
  width: 25%;
  margin: 0 0 0 -12.5%;
  border-top: 1px dashed #999999;
}

.chart--subHeadline {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
}

.charts--headline {
  text-align: center;
  color: #444;
  background-color: #fff;
  padding: 1em;
}

.lineChart--area {
  fill: url(#lineChart--gradientBackgroundArea);
  transform: translateY(1px);
}

.lineChart--area--disabled {
  fill: url(#lineChart--gradientBackgroundAreaDisabled);
  transform: translateY(1px);
}

.lineChart--areaLine {
  fill: none;
  stroke: #1b8db3;
  stroke-width: 3;
  opacity: 0.8;
}

.lineChart--bubble--label {
  fill: #fff;
  stroke: #fff;
  font-size: 12px;
}

.lineChart--bubble--value {
  fill: #fff;
  stroke: #fff;
  font-size: 12px;
  font-weight: bold;
}

.lineChart--circle {
  fill: #242f38;
  /* stroke: #fff;
  stroke-width: 3; */
}

.lineChart--circle__highlighted {
  fill: #242f38;
}

.lineChart--gradientBackgroundArea--top {
  stop-color: #6bb5ce;
  stop-opacity: 0.8;
}

.lineChart--gradientBackgroundArea--bottom {
  stop-color: #b0d7e5;
  stop-opacity: 0.8;
}

.lineChart--gradientBackgroundAreaDisabled--top {
  stop-color: #E1E4E6;
  stop-opacity: 0.8;
}

.lineChart--gradientBackgroundAreaDisabled--bottom {
  stop-color: #D2D4D6;
  stop-opacity: 0.8;
}

.lineChart--xAxisTicks .domain,
.lineChart--xAxis .domain,
.lineChart--yAxisTicks .domain {
  display: none;
}

.lineChart--xAxis .tick line {
  display: none;
}

.lineChart--xAxisTicks .tick line,
.lineChart--yAxisTicks .tick line {
  fill: none;
  stroke: #242f38;
  stroke-width: 2;
  stroke-dasharray: 1, 6;
  opacity: 0.15;
  stroke-linecap: round;
}

/**
   * Helper classes
   */
.hidden {
  display: none;
}
