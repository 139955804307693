@import "../../styles/utils";

.comingSoon {
  position: absolute;
  top: 3.2rem;
  left: 3.2rem;

  padding: 1.6rem;
  max-width: 28.4rem;

  border-radius: .8rem;
  background-color: $light-grey;
}

.comingSoonTitle {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 2.2rem;
}

.comingSoonSubtitle {
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.8rem;

  margin-bottom: .6rem;
}
