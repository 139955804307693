.slate-translator-wrapper {
  p:last-child {
    margin-bottom: 0;

    .mb-48 {
      margin-bottom: 0;
    }
  }

  p[class*="mb-48"]~ul {
    margin-top: -3rem;
    font-size: 1.4rem;
  }
}