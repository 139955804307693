$background-blue: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(90deg, #12436E 0%, #AEEBE9 100%);
$background-red: linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 89.58%), linear-gradient(90deg, #950E2D 0%, #DD1F4A 30.73%, #F9E6C6 100%);

// Estilo base
.styled-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $background-blue;
  z-index: -1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $background-red;
    mix-blend-mode: multiply;
  }
}