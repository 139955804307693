@import "../../styles/utils";

.header {
  width: 100%;
  height: 8rem;
  z-index: 9;
  padding-left: 4.8rem;
  padding-right: 4.8rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: white;
  transition: all .3s ease;
  box-shadow: 0 18px 60px -30px rgba(1,50,62,0.2);


  &.isScrollingDown {
    transition: none;
  }

  @include tablet {
    padding-left: 2.2rem;
    padding-right: 2.2rem;
  }
}

.headerLogo {
  max-height: 4.1rem;
}
